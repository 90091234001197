.skel {
  height: 35px;
  min-height: 35px;
  width: 100%;
}

.lines {
  width: 100%;
  row-gap: 4px;
}

.dark {
  .lineChildrenBase {
    color: rgba(255, 255, 255, 0.7);
  }
}